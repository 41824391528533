import { useRouter, useRoute } from "vue-router";
import { ref, reactive, inject, watch, onMounted, computed } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import moment from "moment";

export default function useBasic() {
  const router = useRouter();
  const route = useRoute();
  const $http = inject("$http");
  const thousand = inject("thousand");
  const rupiah = inject("rupiah");
  const $q = useQuasar();

  let sHeight = computed(() => {
    return $q.screen.height;
  });

  let sWidth = computed(() => {
    return $q.screen.width;
  });

  let isLoadingData = ref(false);

  let loadThis = async (fun) => {
    isLoadingData.value = true;
    await fun();
    isLoadingData.value = false;
  };

  let waitThis = async (fun) => {
    $q.loading.show();
    await fun();
    $q.loading.hide();
  };

  return {
    router,
    route,
    $http,
    thousand,
    rupiah,
    $q,
    moment,
    isLoadingData,
    ref,
    reactive,
    watch,
    onMounted,
    computed,
    loadThis,
    waitThis,
    sHeight,
    sWidth,
  };
}
