<template>
  <q-card :style="{ height: `${sHeight}px` }" class="column">
    <q-card-section class="bg-primary text-center text-white q-pb-none Poppins">
      Toko Al-Ikhlas
    </q-card-section>
    <q-card-section class="bg-primary">
      <q-item>
        <q-item-section avatar>
          <q-btn round flat dense size="sm" style="border: 2px solid white">
            <q-avatar size="32px" color="primary" text-color="white">
              {{ firstLetter }}
            </q-avatar>
          </q-btn>
        </q-item-section>

        <q-item-section>
          <q-item-label class="text-white text-capitalize">{{
            data.user.nama
          }}</q-item-label>
          <q-item-label caption class="text-blue-3">{{
            data.user.hp
          }}</q-item-label>
        </q-item-section>

        <q-item-section side>
          <q-btn
            icon="edit"
            flat
            color="white"
            @click="
              data.tempuser = JSON.parse(JSON.stringify(data.user));
              dialogUser = true;
            "
          ></q-btn>
        </q-item-section>
      </q-item>
    </q-card-section>
    <q-card-section class="q-pb-none my-bg l-grow">
      <div class="bg-white" v-if="data.period.id">
        <q-item class="rounded-borders q-pa-md">
          <q-item-section>
            <div class="text-caption">Mulai</div>
            <div class="text-bold">
              {{ moment(data.period.dibuka).format("HH:mm, DD MMM YYYY ") }}
            </div>
          </q-item-section>
          <q-separator vertical></q-separator>
          <q-item-section class="q-pl-lg">
            <div class="text-caption">Tutup</div>
            <div class="text-bold">
              {{ moment(data.period.ditutup).format("HH:mm, DD MMM YYYY ") }}
            </div>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>
        <q-btn
          flat
          class="full-width text-bold"
          label="Lihat Cara Pengambilan"
          color="primary"
          @click="$emit('showCaraAmbil')"
        ></q-btn>
      </div>
      <div v-else class="text-bold text-center">
        Tidak ada periode titip uang yang berjalan
      </div>

      <q-btn
        unelevated
        color="orange-9"
        icon="shopping_cart"
        label="PESAN SEKARANG"
        to="/catalog"
        class="full-width q-mt-lg"
        :disable="isPeriodActive == 'false'"
      ></q-btn>

      <!-- <q-input
        outlined
        class="bg-white q-mt-md overflow-hidden"
        placeholder="Cari nama atau no.hp"
        v-model="data.searchTerm"
        @keyup.enter="hidekey.focus()"
      >
        <template v-slot:prepend>
          <q-icon name="search" />
        </template>
      </q-input> -->
    </q-card-section>
    <!-- <q-card-section class="text-bold my-bg q-pt-none">
      <div ref="hidekey">Daftar Pesanan</div></q-card-section
    > -->
    <!-- <q-separator></q-separator>
    <q-scroll-area class="my-bg l-grow" visible>
      <q-list class="q-pa-md q-gutter-y-md bg-transparent">
        <q-card v-for="(val, i) in filteredList" :key="i">
          <q-card-section class="row justify-between text-grey-5">
            <div>Waktu Pemesanan</div>
            <div>{{ moment(val.created).format("DD-MM-YYYY, HH:mm") }}</div>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section class="row justify-between items-center">
            <div class="q-gutter-y-sm">
              <div class="text-capitalize text-bold">{{ val.nama }}</div>
              <div>
                {{ val.hp.match(/.{1,4}/g).join("-") }}
              </div>
            </div>
            <div class="text-bold">
              <span v-if="val.status == 'WAIT'" class="text-primary">
                Menunggu
              </span>
              <span v-if="val.status == 'ACC'" class="text-positive">
                Diproses
              </span>
              <span v-if="val.status == 'DEC'" class="text-negative">
                Ditolak
              </span>
            </div>
          </q-card-section>
        </q-card>
        <div v-if="filteredList.length == 0" class="text-center">
          Belum Ada Pesanan
        </div>
      </q-list>
    </q-scroll-area>
    <q-page-sticky position="bottom-right" :offset="fabOfset">
      <q-btn
        to="/catalog"
        :disable="isPeriodActive == 'false'"
        fab
        icon="add"
        color="primary"
      />
    </q-page-sticky> -->

    <q-dialog v-model="dialogUser" persistent>
      <q-card style="width: 300px">
        <q-card-section class="row items-center justify-between">
          <div class="text-h6">Ganti Pengguna</div>
        </q-card-section>
        <q-card-section>
          <div class="column q-gutter-sm">
            <div class="column q-gutter-xs">
              <span>Nama</span>
              <q-input outlined v-model="data.tempuser.nama"></q-input>
            </div>
            <div class="column q-gutter-xs">
              <span>No. Hp</span>
              <q-input type="tel" outlined v-model="data.tempuser.hp"></q-input>
            </div>
            <div class="column q-gutter-xs">
              <span>Kelompok</span>
              <q-select
                outlined
                v-model="data.tempuser.kelompok"
                :options="data.optKelompok"
              ></q-select>
            </div>
          </div>
        </q-card-section>
        <q-card-section class="row justify-end">
          <q-btn
            flat
            label="Batal"
            color="primary"
            v-if="data.user.nama"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            label="SIMPAN"
            @click="setUser"
            color="primary"
          ></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import useBasic from "@/composables/useBasic";
import dialogPengambilan from "@/components/DialogPengambilan";
import { computed } from "@vue/runtime-core";
export default {
  components: [dialogPengambilan],
  setup() {
    const {
      reactive,
      ref,
      onMounted,
      route,
      router,
      loadThis,
      isLoadingData,
      $http,
      moment,
      waitThis,
      $q,
      sHeight,
      sWidth,
    } = useBasic();

    let data = reactive({
      user: {},

      tempuser: {
        nama: "",
        hp: "",
        kelompok: "",
      },

      optKelompok: [
        "Buaran Indah",
        "BTN",
        "Cipondoh",
        "Karang Sawah",
        "Poris",
        "Lainnya",
      ],

      order: [],
      period: {},
      searchTerm: "",
    });

    let firstLetter = computed(() => {
      if (!data?.user?.nama) {
        return "-";
      } else {
        return data?.user?.nama.toUpperCase()[0];
      }
    });

    let fabOfset = computed(() => {
      if (sWidth.value > 425) {
        let extra = sWidth.value - 425;
        return [extra / 2 + 18, 18];
      } else {
        return [18, 18];
      }
    });

    onMounted(() => {
      getUser();
      let isActive = localStorage.getItem("period-active");
      if (isActive == "true") {
        isPeriodActive.value = isActive;
      } else {
        isPeriodActive.value = "false";
      }
      let id = localStorage.getItem("period-id");
      if (id > 0) {
        loadThis(getPeriod);
        loadThis(getOrder);
      }

      // if (route.name == "Finish") {
      //   showPengambilan();
      // }
    });

    let showPengambilan = () => {
      $q.dialog({
        component: dialogPengambilan,
      });
    };

    let getPeriod = async () => {
      let resp = await $http.get(`/active-period`);
      data.period = resp.data[0];
    };

    let getOrder = async () => {
      let resp = await $http.get(
        `/list-order/${localStorage.getItem("period-id")}/DESC`
      );
      data.order = resp.data;
    };

    let getUser = () => {
      let resp = localStorage.getItem(process.env.VUE_APP_USER_NAME);
      try {
        if (resp) {
          data.user = JSON.parse(resp);
        } else {
          dialogUser.value = true;
        }
      } catch (err) {
        dialogUser.value = true;
      }
    };

    let setUser = () => {
      try {
        if (
          data.tempuser.nama != "" &&
          data.tempuser.hp != "" &&
          data.tempuser.kelompok != ""
        ) {
          data.user = JSON.parse(JSON.stringify(data.tempuser));
          let str = JSON.stringify(data.tempuser);
          localStorage.setItem(process.env.VUE_APP_USER_NAME, str);
          $q.notify({
            message: "Berhasil menyimpan data diri!",
            color: "positive",
          });
          dialogUser.value = false;
        } else {
          $q.notify({
            message: "Seluruh data harus diisi!",
            color: "negative",
          });
        }
      } catch (err) {
        $q.notify({ message: "Gagal menyimpan data diri!", color: "negative" });
      }
    };

    let filteredList = computed(() => {
      let temp = data.order.filter((val) => {
        let cNama =
          val.nama.toLowerCase().indexOf(data.searchTerm.toLowerCase()) != -1;
        let cHP =
          val.hp.toLowerCase().indexOf(data.searchTerm.toLowerCase()) != -1;

        return cNama || cHP;
      });
      return temp;
    });

    let dialogUser = ref(false);
    let isPeriodActive = ref(false);
    let hidekey = ref(null);

    return {
      data,
      dialogUser,
      sHeight,
      setUser,
      isPeriodActive,
      isLoadingData,
      filteredList,
      moment,
      firstLetter,
      fabOfset,
      hidekey,
    };
  },
};
</script>
