<template>
  <q-layout class="bg-grey-6" view="hHh lpR fFf">
    <q-page-container>
      <router-view
        v-if="isShow"
        class="l-mobile-container bg-white Poppins"
        @showCaraAmbil="showPengambilanBaru"
        :style="{ height: `${sHeight}px` }"
      />
    </q-page-container>
  </q-layout>
</template>

<script>
import useBasic from "@/composables/useBasic";
import dialogPengambilan from "@/components/DialogPengambilan";
import { computed, onMounted, reactive, watch } from "vue";
export default {
  components: [dialogPengambilan],
  setup() {
    const { route, router, $http, ref, moment, sHeight, waitThis, $q } =
      useBasic();

    let data = reactive({
      curPeriod: {},
    });

    watch(route, async (to, from) => {
      if (to.path == "/finish-payment") {
        await showPengambilanBaru();
      }
    });

    onMounted(async () => {
      await waitThis(getPeriod);
      isShow.value = true;
    });

    let getPeriod = async () => {
      try {
        let resp = await $http.get("/active-period");
        if (resp.data[0]) {
          data.curPeriod = resp.data[0];
          localStorage.setItem("period-id", resp.data[0].id);
          if (
            moment().isBetween(resp.data[0].dibuka, resp.data[0].ditutup) &&
            resp.data[0].is_closed == 0
          ) {
            localStorage.setItem("period-active", true);
          } else {
            localStorage.setItem("period-active", false);
          }
        } else {
          localStorage.removeItem("period-id");
          localStorage.removeItem("period-active");
        }
      } catch (err) {
        localStorage.removeItem("period-id");
        localStorage.removeItem("period-active");
      }
    };

    let showPengambilan = () => {
      $q.dialog({
        component: dialogPengambilan,
      });
    };

    let showPengambilanBaru = async () => {
      await router.push("/");
      $q.dialog({
        component: dialogPengambilan,
      });
    };

    let logout = () => {
      localStorage.removeItem("token-toko-masjid");
      router.push("/");
    };

    let isShow = ref(false);

    return {
      data,
      route,
      router,
      showPengambilan,
      sHeight,
      logout,
      isShow,
      showPengambilanBaru,
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/quasar.scss";
.stickyTable {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: $blue-1;
    color: $blue-8;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
    // border: 0.1px solid grey;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
}
.l-container {
  height: 100vh;
}
.l-mobile-container {
  max-width: 425px;
  min-width: 300px;
  // height: calc(100vh - 51px);
  // height: v-bind(sHeight) + "px";

  margin: 0 auto;
}
.l-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.l-grow {
  flex-grow: 99;
}

@font-face {
  font-family: customfont;
  src: url("./css/fonts/times.ttf");
}
@font-face {
  font-family: Poppins;
  src: url("./css/fonts/Poppins-Bold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: Poppins;
  src: url("./css/fonts/Poppins-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Poppins;
  src: url("./css/fonts/Poppins-Regular.ttf");
  font-weight: 400;
}

.my-font {
  font-family: "customfont";
}

.my-bg {
  background-color: #f6faff;
}

.Poppins {
  font-family: "Poppins";
}

.f10 {
  font-size: 10pt;
}
.f12 {
  font-size: 12pt;
}
.f14 {
  font-size: 14pt;
}
.f16 {
  font-size: 16pt;
}
.f18 {
  font-size: 18pt;
}
.f20 {
  font-size: 20pt;
}
</style>
