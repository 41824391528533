import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import axios from "axios";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/finish-payment",
    name: "Finish",
    component: Home,
  },
  {
    path: "/catalog",
    component: () => import("../views/Catalog.vue"),
    meta: {
      needUser: true,
    },
  },
  {
    path: "/payment",
    component: () => import("../views/Payment.vue"),
    meta: {
      needUser: true,
    },
  },
  {
    path: "/admin",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/admin/menu",
    component: () => import("../views/Menu.vue"),
    meta: {
      needToken: true,
    },
  },
  {
    path: "/admin/list-order",
    component: () => import("../views/List-Order.vue"),
    meta: {
      needToken: true,
    },
  },
  {
    path: "/admin/list-po",
    component: () => import("../views/List-PO.vue"),
    meta: {
      needToken: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
  let user = localStorage.getItem(process.env.VUE_APP_USER_NAME);

  if (to.meta.needToken) {
    try {
      let resp = await axios.get(`${process.env.VUE_APP_BASE_URL}/user_data`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (resp.data.valid == 1) {
        next();
      }
    } catch (error) {
      console.log(error);
      console.log("gagal menarik data admin");
      next("/admin");
    }
  } else if (to.meta.needUser) {
    try {
      let temp = JSON.parse(user);
      if (temp.nama && temp.hp && temp.kelompok) {
        to.meta.user = temp;
        next();
      } else {
        next("/");
      }
    } catch (err) {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
