<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin Poppins" style="width: 350px">
      <q-card-section class="row justify-between items-center">
        <a class="text-weight-bold">Cara Pengambilan</a>
        <q-btn
          flat
          dense
          icon="close"
          @click="onCancelClick"
          size="sm"
          color="primary"
        ></q-btn>
      </q-card-section>
      <q-card-section class="my-bg">
        <div class="q-pa-md text-bold text-center text-grey">
          Jadwal pengambilan Barang:
        </div>
        <q-list>
          <q-item>
            <q-item-section class="text-grey">Tanggal</q-item-section>
            <q-item-section class="text-right text-bold">
              {{
                moment(data.curPeriod.tanggal_pengambilan).format(
                  "DD MMM YYYY "
                )
              }}
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section class="text-grey">Waktu</q-item-section>
            <q-item-section class="text-right text-bold">
              {{ moment(data.curPeriod.tanggal_pengambilan).format("HH:mm") }}
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section class="text-grey">Lokasi</q-item-section>
            <q-item-section class="text-right text-bold">
              Karang Sawah
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { inject, onMounted, reactive, ref } from "vue";
import useBasic from "@/composables/useBasic";
export default {
  emits: [...useDialogPluginComponent.emits],

  setup() {
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
      useDialogPluginComponent();

    const {
      waitThis,
      onMounted,
      route,
      $http,
      ref,
      $q,
      loadThis,
      isLoadingData,
      moment,
    } = useBasic();

    let data = reactive({
      curPeriod: {},
    });

    onMounted(async () => {
      waitThis(getPeriod);
    });

    let onOKClick = () => {
      onDialogOK();
    };

    let getPeriod = async () => {
      let resp = await $http.get("/active-period");
      data.curPeriod = resp.data[0];
    };

    return {
      dialogRef,
      onDialogHide,
      onOKClick,
      onCancelClick: onDialogCancel,
      route,
      loadThis,
      isLoadingData,
      data,

      moment,
    };
  },
};
</script>
